import { Notification } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { Button } from "src/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { useGetSocketIo } from "src/hooks/useGetSocketIo";
import { useCurrentUser } from "src/pages/SignIn/api/queries";
import NotificationDropdownMenuContent from "./NotificationDropdownMenuContent";
import { Dot } from "lucide-react";

interface NotificationDropdownMenuProps {
  isOpen: boolean;
  handleToggle: () => void;
}

const NotificationDropdownMenu: React.FC<NotificationDropdownMenuProps> = ({
  isOpen,
  handleToggle,
}) => {
  const [hasUnReadNotification, setHasUnReadNotification] = useState(false);

  const { data } = useCurrentUser();
  const socket = useGetSocketIo();

  const adminRoleId = data?.data.admin_role_id;
  const hasUnreadNotif = data?.data.unread_notifications;

  useEffect(() => {
    if (hasUnreadNotif) setHasUnReadNotification(true);
  }, [hasUnreadNotif]);

  useEffect(() => {
    socket.emit("listen-notif", `${adminRoleId}`);

    socket.on("notif", (n) => {
      setHasUnReadNotification(true);
    });

    return () => {
      socket.close();
    };
  }, [adminRoleId, socket]);

  return (
    <DropdownMenu open={isOpen} onOpenChange={handleToggle}>
      <DropdownMenuTrigger asChild>
        <Button className="px-0" variant="ghost">
          <div className="relative">
            <Notification size="32" color={"#433C34"} variant="Bold" />

            {hasUnReadNotification && (
              <Dot
                className="absolute -top-5 -right-5"
                size={56}
                color="#A92323"
              />
            )}
          </div>
        </Button>
      </DropdownMenuTrigger>

      {isOpen && <NotificationDropdownMenuContent />}
    </DropdownMenu>
  );
};

export default NotificationDropdownMenu;
