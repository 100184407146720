import { io } from "socket.io-client";
import { getBaseURL } from "src/services";
import { getAuthTokens } from "src/utils/getAuthTokens";

export const useGetSocketIo = () => {
  const baseURL = getBaseURL();
  const { token } = getAuthTokens();

  const socket = io(baseURL, {
    query: {
      access_token: token,
    },
  });

  return socket;
};
